<template>
  <v-app style="background-color:#F6F7FF;">

       <v-navigation-drawer   v-model="drawer" :clipped="$vuetify.breakpoint.lgAndUp" v-if="logeado==true" app>
        <v-list dense v-if="logeado==true">
          <template v-for="item in items">
            <v-row  v-if="item.heading" :key="item.heading"  align="center">
              <v-col cols="6">
                <v-subheader v-if="item.heading">
                  {{ item.heading }}
                </v-subheader>
              </v-col>
             <v-col cols="6" class="text-center">
                <a href="#!" class="body-2 black--text">EDIT</a>
              </v-col>
            </v-row>


        

            <!--opcion de unica fila de menu    esta, esta  funcionando-->
            <!-- aqui se van a ver todas las opciones excepto las de administracion -->
            <v-list-item v-if="((item.protegido==false || esAdminstrador==true) && (!item.children))" :key="item.text"  :to="{name:item.to}"  link>
              <v-list-item-action>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

                <!-- opcion de menu desplegable--->
            <v-list-group v-else-if="item.children" :key="item.text" v-model="item.model"
              :prepend-icon="item.model ? item.icon : item['icon-alt']" append-icon="">
              <template v-slot:activator>
                <v-list-item-content >
                  <v-list-item-title>
                    {{ item.text }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item class="ml-4"  v-for="(child, i) in item.children" :key="i" :to="{name:child.to}" link>
                <v-list-item-action v-if="child.icon">
                  <v-icon small>{{ child.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ child.text }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>



          </template>
        </v-list>
      </v-navigation-drawer>
  



      <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" app color="blue darken-3"  dark>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <v-toolbar-title style="width: 300px" class="ml-0 pl-4">
          <span class="hidden-sm-and-down">Mantenimientos Disatel</span>
        </v-toolbar-title>
        
        <v-spacer></v-spacer>
        <v-btn icon large v-if="logeado!=null" @click="salir()">
         <v-icon>exit_to_app</v-icon>
        </v-btn>
        <v-btn v-else :to="{name:'login'}" color="success">
          <v-icon>login</v-icon>
        </v-btn>
      </v-app-bar>


      <v-main>

         <v-container style="width:100%">
           <v-slide-y-transition mode="out-in">
             <router-view/>
        </v-slide-y-transition>


         <v-dialog
                   v-model="loading.estado"
                    hide-overlay
                     persistent
                     width="300">
                        <v-card
                          color="info"
                          dark
                        >
                          <v-card-text>
                          {{loading.titulo}}
                            <v-progress-linear
                              indeterminate
                              color="white"
                              class="mb-0"
                            ></v-progress-linear>
                          </v-card-text>
                        </v-card>
                </v-dialog>


         </v-container>
      </v-main>
      
  </v-app>
</template>

<script>


import { mapState,mapMutations } from "vuex";

export default {
  name: 'App',

  components: {
   
  },

  data: () => ({
    
    dialog: false,
    drawer: null,
    arrayDrawer:[],
    items: [

       { icon: 'home', text: 'Inicio', to:'home', protegido:false  },
       { icon: 'time_to_leave', text: 'Vehiculos', to:'vehiculos', protegido:false  },
       { icon: 'commute', text: 'Flotas', to:'flotas', protegido:false  },

         {
        icon: 'expand_less',  'icon-alt': 'expand_more',
        text: 'Mantenimientos', model: false,
        children: [
              { icon: 'today', text: 'Agendar', to:'mantenimiento', protegido:false },
              { icon: 'build_circle', text:'Correctivos', to:'correctivo', protegido:false},
              { icon: 'format_list_numbered', text: 'Tipos', to:'tipos', protegido:false  },
              { icon: 'checklist_rtl', text: 'Items', to:'items', protegido:false  },
              { icon: 'home_repair_service', text: 'Talleres', to:'talleres', protegido:false },
              { icon: 'history', text: 'Historial', to:'historial', protegido:false  },
        ],
      },

      

       { icon: 'battery_charging_full', text: 'Baterias', to:'baterias', protegido:false  },
       { icon: 'data_saver_on', text: 'llantas', to:'llantas', protegido:false  },
       { icon: 'insert_chart', text: 'Graficos', to:'graficos', protegido:false  },


       { icon: 'engineering', text: ' Configuracion', to:'configuracion', protegido:true }, //solo admin va verlo
      
     
      
    

       /* datos comentarizados
      {
        icon: 'chevron-up',
        'icon-alt': 'expand_more',
        text: 'More',
        model: false,
        children: [
          { text: 'Import' },
          { text: 'Export' },
          { text: 'Print' },
          { text: 'Undo changes' },
          { text: 'Other contacts' },
        ],
      }*/
    ],
  }),
  computed:{
      ...mapState(['loading']),
      
      logeado(){

        let token_storage=localStorage.getItem('token');

        if(token_storage){
          console.log('se detecto cambio en storage:'+this.$store.state.usuario);
          return true;
        }else{

           console.log('se detecto cambio en storage:'+this.$store.state.usuario+' --->');
          return false;
        }
       
         // return this.$store.state.usuario;
      }, 
      
      esAdminstrador(){
          return this.$store.state.usuario && this.$store.state.usuario.rol=='ADMIN';
      }, 
      
      esUsuarioComun(){
          return this.$store.state.usuario && this.$store.state.usuario.rol=='USER';
      }

  },
  created(){
   //this.$store.dispatch("autoLogin");
  
  },


  methods:{
      ...mapMutations(['mostrarLoading','ocultarLoading']),

      salir(){
          this.drawer=false;
          this.$store.dispatch("salir");
      }

  }
};
</script>
