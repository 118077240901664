import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue';

import store from '../store';

Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    name: 'inicio',
    component: () => import(/* webpackChunkName: "vehiculos" */ '../views/Inicio.vue'),
    meta:{
      libre:true
    }
  },
  {
    path: '/home',
    name: 'home',
    component: () => import(/* webpackChunkName: "vehiculos" */ '../views/Home.vue'),
    meta:{
      administrador:true,
      usuario_comun:true,
    }
  },
  {
    path: '/vehiculos',
    name: 'vehiculos',
    component: () => import(/* webpackChunkName: "vehiculos" */ '../views/Vehiculos.vue'),
    meta:{
      administrador:true,
      usuario_comun:true,
    }
  },

  {
    path: '/configuracion',
    name: 'configuracion',
    component: () => import(/* webpackChunkName: "vehiculos" */ '../views/Configuracion.vue'),
    meta:{
      administrador:true,
      usuario_comun:true,
    }
  },
  {
    path: '/tipos',
    name: 'tipos',
    component: () => import(/* webpackChunkName: "vehiculos" */ '../views/Tipos.vue'),
    meta:{
      administrador:true,
      usuario_comun:true,
    }
  }
  ,
  {
    path: '/talleres',
    name: 'talleres',
    component: () => import(/* webpackChunkName: "talleres" */ '../views/Talleres.vue'),
    meta:{
      administrador:true,
      usuario_comun:true,
    }
  } ,
  {
    path: '/mantenimiento',
    name: 'mantenimiento',
    component: () => import(/* webpackChunkName: "mantenimiento" */ '../views/Mantenimientos.vue'),
    meta:{
      administrador:true,
      usuario_comun:true,
    }
  },
  {
    path: '/correctivo',
    name: 'correctivo',
    component: () => import(/* webpackChunkName: "mantenimiento" */ '../views/Correctivos.vue'),
    meta:{
      administrador:true,
      usuario_comun:true,
    }
  },
  {
    path: '/historial',
    name: 'historial',
    component: () => import(/* webpackChunkName: "historial" */ '../views/Historial.vue'),
    meta:{
      administrador:true,
      usuario_comun:true,
    }
  },
  {
    path: '/servidores',
    name: 'servidores',
    component: () => import(/* webpackChunkName: "historial" */ '../views/ServidoresSmtp.vue'),
    meta:{
      administrador:true,
      usuario_comun:true,
    }
  },
  {
    path: '/baterias',
    name: 'baterias',
    component: () => import(/* webpackChunkName: "baterias" */ '../views/Baterias.vue'),
    meta:{
      administrador:true,
      usuario_comun:true,
    }
  },
  {
    path: '/llantas',
    name: 'llantas',
    component: () => import(/* webpackChunkName: "llantas" */ '../views/Llantas.vue'),
    meta:{
      administrador:true,
      usuario_comun:true,
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    meta:{
      libre:true
    }
  },
  {
    path: '/flotas',
    name: 'flotas',
    component: () => import(/* webpackChunkName: "flota" */ '../views/Flotas.vue'),
    meta:{
      administrador:true,
      usuario_comun:true,
    }
  },
  {
    path: '/graficos',
    name: 'graficos',
    component: () => import(/* webpackChunkName: "graficos" */ '../views/Graficos.vue'),
    meta:{
      administrador:true,
      usuario_comun:true,
    }
  },
  {
    path: '/items',
    name: 'items',
    component: () => import(/* webpackChunkName: "graficos" */ '../views/Items.vue'),
    meta:{
      administrador:true,
      usuario_comun:true,
    }
  }






];

const router = new VueRouter({
  mode: 'history',
  routes
});






router.beforeEach((to, from, next)=>{



  //si alguien recarga la url debemos tomar ese token e ingresarlo al state

const token_storage=localStorage.getItem('token');

if(token_storage!=null){


  if(!store.state.usuario){

    console.log('token encontrado');
    console.log(token_storage);
    store.dispatch("guardarToken", token_storage);
  }

}




  if(to.matched.some(record=>record.meta.libre)){
    next();


    //validamos rutas solo administrador
  }else if( store.state.usuario && store.state.usuario.rol=='ADMIN'){

    if(to.matched.some(record=>record.meta.administrador)){
      next();
    }

    //ruta para usuarios comunes
  }else if(store.state.usuario && store.state.usuario.rol=='USER'){

    if(to.matched.some(record=>record.meta.usuario_comun)){
          next();
        }

    //si no tiene ningun permiso salir
  }else{
    next({name:'login'});

    
    console.log('entro en ruta libre');

  }

});




export default router
