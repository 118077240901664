<template>
  <v-container class="mt-5">

  

<v-row>


<!-- columna para indicadores de home -->
  <v-col cols="4" class="mt-5">
       <v-row>


    
        <!--  diseño card de vehiculos-->
       <v-col cols="12">
          <v-card class="mt-4 mx-auto" max-width="300">
          
          <v-sheet class="v-sheet--offset mt-n15" color="cyan" elevation="5" max-width="calc(100% - 202px)"  rounded height="60px">
            <div>
            <v-icon dark x-large class="ml-6 mt-3">time_to_leave</v-icon>
            </div>
          </v-sheet>

            <div class="title font-weight-light black--text  text-right mx-4">{{ unidades_avl }} vehiculos</div>
            <v-divider class="my-2  text-right"></v-divider>
            <v-icon class="mr-2" small>location_on</v-icon>
            <span class="caption grey--text font-weight-light  text-right">Unidades actuales en sistema</span>
        
        </v-card>

       </v-col>




  <!--  diseño card de flotas-->
      <v-col cols="12" class="mt-8">
      <v-card class="mt-4 mx-auto" max-width="300">
      
      <v-sheet class="v-sheet--offset mt-n15" color="success" elevation="5" max-width="calc(100% - 202px)"  rounded height="60px">
        <div>
        <v-icon dark x-large class="ml-6 mt-3">commute</v-icon>
        </div>
      </v-sheet>

        <div class="title font-weight-light black--text  text-right mx-4">{{ flotas_avl }} flotas</div>
        <v-divider class="my-2  text-right"></v-divider>
        <v-icon class="mr-2" small>public</v-icon>
        <span class="caption grey--text font-weight-light  text-right">Flotas en sistema</span>
     
    </v-card>

      </v-col>





    <!--  diseño card de agendados-->
      <v-col cols="12" class="mt-8">
      <v-card class="mt-4 mx-auto" max-width="300">
      
      <v-sheet class="v-sheet--offset mt-n15" color="indigo" elevation="5" max-width="calc(100% - 202px)"  rounded height="60px">
        <div>
        <v-icon dark x-large class="ml-6 mt-3">date_range</v-icon>
        </div>
      </v-sheet>

        <div class="title font-weight-light black--text  text-right mx-4">{{ mant_agendados }} agendados</div>
        <v-divider class="my-2  text-right"></v-divider>
        <v-icon class="mr-2" small>today</v-icon>
        <span class="caption grey--text font-weight-light  text-right">Mantenimientos agendados</span>
     
    </v-card>

      </v-col>



        <!--  diseño card de sincronizacion-->
       <v-col cols="12" class="mt-8">
          <v-card class="mt-4 mx-auto" max-width="300">
          
          <v-sheet class="v-sheet--offset mt-n15" color="red" elevation="5" max-width="calc(100% - 202px)"  rounded height="60px">
            <div>
            <v-icon dark x-large class="ml-6 mt-3">warning</v-icon>
            </div>
          </v-sheet>

            <div class="title font-weight-light black--text  text-right mx-4">{{ criticos }} criticos</div>
            <v-divider class="my-2  text-right"></v-divider>
            <v-icon class="mr-2" small>info</v-icon>
            <span class="caption grey--text font-weight-light  text-right">Mantenimientos estado critico</span>
        
        </v-card>

       </v-col>


         <!--  diseño card de odometros con errores-->
      <v-col cols="12" class="mt-8">
      <v-card class="mt-4 mx-auto" max-width="300">
      
      <v-sheet class="v-sheet--offset mt-n15" color="alerta" elevation="5" max-width="calc(100% - 202px)"  rounded height="60px">
        <div>
        <v-icon dark x-large class="ml-6 mt-3">error</v-icon>
        </div>
      </v-sheet>

        <div class="title font-weight-light black--text  text-right mx-4">{{ error_odometro }}  error odometro</div>
        <v-divider class="my-2  text-right"></v-divider>
        <v-icon class="mr-2" small>error</v-icon>
        <span class="caption grey--text font-weight-light  text-right">Vehiculos con error en odometro</span>
     
    </v-card>

      </v-col>


       </v-row>
  </v-col>



<!--  columna para las tablas-->
    <v-col cols="8" class="pt-0">

      <v-card class="pt-0">
         <v-toolbar flat color="red">
            <v-toolbar-title class="white--text">Mantenimientos criticos</v-toolbar-title>
         </v-toolbar>
         
        

      <v-data-table
        :headers="headersCritico"
        :items="ArrayCritico"
        height="200px"
      >
        <template v-slot:[`item.id_vehiculo_nombre`]="{ item }">
            <span>{{item.id_vehiculo_mant.nombre}}</span>
          </template>

          
            <template v-slot:[`item.id_vehiculo_flota`]="{ item }"> 
            <span>{{item.id_vehiculo_mant.flota}}</span>
            </template>

           <template v-slot:[`item.id_tipo_mant`]="{ item }"> 
           <span>{{item.id_tipo_mant.nombre_tipo}} / {{item.id_tipo_mant.cantidad_km}} km</span>
           </template>

      
      </v-data-table>

      </v-card>








      <v-card class="my-6">

         <v-toolbar flat color="info">
            <v-toolbar-title class="white--text">Proximos Mantenimientos</v-toolbar-title>
         </v-toolbar>
         

      <v-data-table
        :headers="headersCritico"
        :items="ArrayAlerta"
         height="200px"
      >


        <template v-slot:[`item.id_vehiculo_nombre`]="{ item }">
            <span>{{item.id_vehiculo_mant.nombre}}</span>
          </template>

          
            <template v-slot:[`item.id_vehiculo_flota`]="{ item }"> 
            <span>{{item.id_vehiculo_mant.flota}}</span>
           </template>

            <template v-slot:[`item.id_tipo_mant`]="{ item }"> 
           <span>{{item.id_tipo_mant.nombre_tipo}} / {{item.id_tipo_mant.cantidad_km}} km</span>
           </template>

      
      
      </v-data-table>

      </v-card>
        
    </v-col>


   



</v-row>
   
   
   
  </v-container>
</template>

<script>

import { mapState,mapMutations } from "vuex";
import axios from 'axios';


export default {
  name: 'Home',
  components: {
  
  },
  data(){
    return{
       unidades_avl:0,
       flotas_avl:0,
       mant_agendados:0,
       tiempo_hace:10,
       criticos:0,
       error_odometro:0,

        search: '',
        headersCritico: [
        { text: 'Vehiculo', value: 'id_vehiculo_nombre' },
        { text: 'Flota', value: 'id_vehiculo_flota' },
        { text: 'Tipo Mantenimiento', value: 'id_tipo_mant' },
        { text: 'Remanente', value: 'remanente' },
        ],
        ArrayCritico: [],
        ArrayAlerta:[],
        ArregloMantenimientos:[],



    }
  },
  created(){

  // this.$store.dispatch("autoLogin");

  this.listarVehiculos();
  this.listarFlotas();
  this.listarAgendados();

  }, 
  methods:{

  ...mapMutations(['mostrarLoading','ocultarLoading']),


     listarVehiculos(){

                let Arrayflota=[];
                this.error_odometro=0;
                this.unidades_avl=0;

                 this.mostrarLoading({titulo:'Accediendo a datos'});
                 let config={headers:{token:this.token}};
                let me=this;
                axios.get(`vehiculos/${this.usuario.id_usr_empresa._id}`,
                    config
                    ).then(function(response){
                        console.log(response);
                        Arrayflota=response.data;

                        Arrayflota.map(function(x){

                          if(x.observacion){
                                if(x.observacion!=''){
                                     me.error_odometro++;
                                   }
                          }
                            
                        });
                        me.unidades_avl= Arrayflota.length;
                        me.ocultarLoading();
                    }).catch(function(error){ console.log(error); });

    },

      listarFlotas(){

               let arrayitemFlota=[];

                 this.mostrarLoading({titulo:'Accediendo a datos'});
                 let config={headers:{token:this.token}};
                let me=this;
                axios.get(`flotas/${this.usuario.id_usr_empresa._id}`,
                    config
                    ).then(function(response){
                      
                          arrayitemFlota=response.data;
                          me.flotas_avl=arrayitemFlota.length;
                     
                          me.ocultarLoading();

                          console.log(arrayitemFlota);
                    }).catch(function(error){ console.log(error); });

    },

     listarAgendados(){

              this.ArregloMantenimientos=[];
              this.ArrayCritico=[];
              this.ArrayAlerta=[];
              this.criticos=0;

              this.mostrarLoading({titulo:'Accediendo a datos'});
              let config={headers:{token:this.token}};
              let me=this;
              axios.get(`mantenimientos/${this.usuario.id_usr_empresa._id}`,
                  config
                  ).then(function(response){
                      console.log(response);
                      me.ArregloMantenimientos=response.data;
                      me.mant_agendados=me.ArregloMantenimientos.length;

                      me.ArregloMantenimientos.map(function(x){
                        if(parseFloat(x.remanente) >=0){
                           me.ArrayCritico.push(x); 
                           me.criticos++;
                        }else{  //si aun esta en modo alerta

                              let odom_actual=0;

                                if(x.id_vehiculo_mant.tipo_odo_activo=='Software'){
                                  odom_actual=x.id_vehiculo_mant.odometro_sw;
                                }else if(x.id_vehiculo_mant.tipo_odo_activo=='Hardware'){
                                    odom_actual=x.id_vehiculo_mant.odometro_hw;
                               }

                              let recorrido=parseFloat(odom_actual)-parseFloat(x.odometro_anterior_mant);
                              let porcentaje=0;

                                if(recorrido > 0){
                                  porcentaje=recorrido/parseFloat(x.kms_realizar);
                                  porcentaje=porcentaje*100;
                                }

                            //sumamos lo que ya lleva realizado en porcentaje + valor de alerta por remantente 
                            //si excede el 100% indica que ya esta en proximo a realizarse
                              let cantidad_porcentaje=porcentaje + parseFloat(x.id_tipo_mant.porcentaje_alerta)+10;//sumamos 10% para Para aproximar
            
                            if(cantidad_porcentaje>=100){
                                      me.ArrayAlerta.push(x);
                            }




                           
                        }//fin si es tipo alerta aun

                      });

                      me.ocultarLoading();
                  }).catch(function(error){ console.log(error); });

    },



     
  }, computed:{

       ...mapState(['usuario', 'token']),


    }
}
</script>
