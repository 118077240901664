import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';


import axios from 'axios';

import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios);

import VueExcelXlsx from "vue-excel-xlsx";
Vue.use(VueExcelXlsx);


import VueApexCharts from 'vue-apexcharts';
Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts);

Vue.config.productionTip = false

// api servidor local
//axios.defaults.baseURL='http://localhost:3000/api/'

// api servidor sin https 
axios.defaults.baseURL='http://161.35.5.242:3000/api/'

// servidor productivo

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
